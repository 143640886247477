@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/themes' as thm;
@use 'node_modules/@angular/material' as mat;
@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@import 'variables';

$custom-typography: mat.define-typography-config($font-family: '"Titillium Web", sans-serif;',
$body-1: mat.define-typography-level(18px, 27px, 500),
$display-2: mat.define-typography-level(64px, 76.8px, 600),
$title: mat.define-typography-level(32px, 40px, 600),
$subheading-2: mat.define-typography-level(26px, 31.2px, 600),
$subheading-1: mat.define-typography-level(18px, 21px, 600));


@include thm.setTypography($custom-typography);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

/*
 High risk adjustment since Tillium web does not fit properly in inputs
 */
input {
  line-height: 23px !important;
}

mat-label {
  line-height: 22px !important;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: $spb-max-width;
  width: 100%;
}

@media screen and (min-width: 400px) {
  .spb-plain-content {
    // border: 1px solid red;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (max-width: $spb-max-width) and (min-width: $spb-small) {
  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (max-width: 1215px) and (min-width: 400px) {
  .spb-plain-content > * {
    padding-left: 65px;
    padding-right: 20%;
  }
}


@media screen and (max-width: 400px) {
  .spb-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}


/* A yellow button with some text in it. Tries to be centered in a flex layout */
.spb-yellow-button {
  align-self: center;
  background-color: $spb-yellow;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  padding: 14px 30px;
  text-transform: uppercase;
}

.spb-error {
  background-color: $spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}

@media screen and (max-width: $spb-small) {
  .spb-standard-full-width {
    padding: initial;
    width: 100%;
  }
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

a {
  color: #232323;
}

